<!-- 充值 -->
<template>
    <div class="userRecharge center-wrap">
        <div class="page">
            <div class="left-box">
                <v-user-menu></v-user-menu>
            </div>
            <div class="body-box" v-if="!isSubmit">
                <div class="my-box">
                    <div class="title-box">
                        <p v-text="$t('recharge.my_balance')">我的余额</p>
                    </div>
                    <div class="money-text">
                        <p v-text="$t('recharge.input')">请输入充值金额</p>
                        <div class="input-box">
                            <input type="text" :placeholder="$t('recharge.money')" v-model="amount" onkeyup="value=value.replace(/[^\d.]/g,'')" maxlength="8">
                        </div>
                    </div>
                </div>
                <div class="my-box">
                    <div class="title-box">
                        <p v-text="$t('recharge.pay')">选择支付方式</p>
                    </div>
                    <div class="money-text">
                        <div class="mode click" @click.stop="payType = 'wxpay'">
                            <img src="../assets/icon/yes-dot.png" v-if="payType=='wxpay'">
                            <img src="../assets/icon/no-dot.png" v-else>
                            <div>
                                <img src="../assets/icon/wx.png">
                                <p v-text="$t('recharge.wx')">微信</p>
                            </div>
                        </div>
                        <div class="mode click" @click.stop="payType = 'alipay'">
                            <img src="../assets/icon/yes-dot.png" v-if="payType=='alipay'">
                            <img src="../assets/icon/no-dot.png" v-else>
                            <div>
                                <img src="../assets/icon/zfb.png">
                                <p v-text="$t('recharge.zfb')">支付宝</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="all-money">
                    <div>
                        <div class="money-num" v-if="service_amount">
                            <div v-text="$t('recharge.procedures')">手续费：</div>
                            <p>{{common.showPrice(service_amount,userinfo.local)}}</p>
                        </div>
                        <div class="money-num" v-if="total_amount">
                            <div v-text="$t('recharge.all')">合计：</div>
                            <p>{{common.showPrice(total_amount,userinfo.local)}}</p>
                        </div>
                        <div class="recharge">
                            <div class="click" v-text="$t('recharge.botton')" @click.stop="fnRechargeSubmit">充值</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="body-box" v-if="isSubmit">
                <div class="my-box">
                    <div class="title-box">
                        <p v-text="$t('recharge.my_balance')">我的余额</p>
                    </div>
                    <div class="over-text" v-text="$t('recharge.loaging_pay')">
                        正在等待支付结果.....
                    </div>
                </div>
            </div>
        </div>
        <div class="wechat-qrcode-box" v-if="showWechatPayMask">
            <div class="wechat-qrcode-content">
                <img src="../assets/icon/icon-close.png" class="close-icon" @click.stop="showWechatPayMask = false">
                <p v-text="$t('order.wxpay')">微信支付</p>
                <div class="qecode-box" ref="qrcode">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import UserMenu from '../components/userMenu.vue'
import QRCode from 'qrcodejs2'

export default {
    name: 'UserRecharge',
    data() {
        return {
            isSubmit: false,
            amount: null, // 充值金额
            service_amount: null, //手续费
            total_amount: null, //合计
            payType: null, // alipay
            order: null,
            inter: null, //轮询对象
            showWechatPayMask: false,
            hk_ali_serviceFee: localStorage.getItem('hk_ali_serviceFee'), // 香港支付宝支付手续费
            hk_wx_serviceFee: localStorage.getItem('hk_wx_serviceFee'), // 香港微信支付手续费
            ali_serviceFee: localStorage.getItem('ali_serviceFee'), // 大陆支付宝支付手续费
            wx_serviceFee: localStorage.getItem('wx_serviceFee'), // 大陆微信支付手续费
            userinfo: this.common.getUserInfo(),
        }
    },
    created: function() {

    },
    methods: {
        /**
         * 充值提交
         * @return {[type]} [description]
         */
        fnRechargeSubmit() {
            var self = this;
            if (!self.amount) return self.common.showMessage('请输入金额', 'error');
            if (self.isPriceNumber(self.amount) == false) return self.common.showMessage('充值金额有误', 'error');
            if (!self.payType) return self.common.showMessage('请选择支付方式', 'error');

            if (self.payType == 'wxpay') var pay_type = 1;
            if (self.payType == 'alipay') {
                var newWindow = window.open("about:blank", '', 'width=1000,height=850,scrollbars=yes,resizable=1');
                if (newWindow == null || typeof(newWindow) == 'undefined') return alert("关闭您的弹出窗口拦截器！");
                var pay_type = 2;
            }


            self.common.httpPost("/api/user/createRechargeOrder", {
                amount: self.amount,
                pay_type: pay_type
            }).then((ret) => {
                if (ret.code == 1) {
                    self.order = ret.data;
                    if (self.payType == 'alipay') {
                        self.isSubmit = true;
                        // console.log(window.location.host);
                        newWindow.location.href = "https://"+window.location.host+"/api/ajax/userRechargePay?order_code=" + ret.data.order_code
                        setTimeout(function() {
                            self.httpLoop(ret.data.order_code);
                        }, 3000)
                    } else if (self.payType == 'wxpay') {
                        self.common.httpPost("/api/ajax/userRechargePay?order_code=" + ret.data.order_code)
                            .then((ret) => {
                                if (ret.code == 1) {
                                    var url = ret.data;

                                    self.showWechatPayMask = true;

                                    self.$nextTick(function() {
                                        new QRCode(this.$refs.qrcode, {
                                            text: url,
                                            width: 200,
                                            height: 200,
                                            colorDark: "#000000",
                                            colorLight: "#ffffff",
                                            correctLevel: QRCode.CorrectLevel.H
                                        });
                                    })

                                    setTimeout(function() {
                                        self.httpLoop(self.order.order_code);
                                    }, 3000)
                                } else self.common.showMessage(ret.msg, 'error');
                            })
                    }
                }
            })
        },
        httpLoop(order_code) {
            var self = this;
            self.inter = setInterval(function() {
                self.common.httpPost("/api/user/checkRechargeStatus", { order_code, order_code }, false)
                    .then((ret) => {
                        if (ret.code == 1 && ret.data == 1) {
                            clearInterval(self.inter);
                            self.$router.push({
                                path: "/recharge/result",
                                query: {
                                    amount: self.order.in_amount
                                }
                            })
                        }
                    })
            }, 2000)
        },
        isPriceNumber: function(_keyword) {
            if (_keyword == "0" || _keyword == "0." || _keyword == "0.0" || _keyword == "0.00") {
                return false;
            } else {
                var index = _keyword.indexOf("0");
                var length = _keyword.length;
                if (index == 0 && length > 1) { /*0开头的数字串*/
                    var reg = /^[0]{1}[.]{1}[0-9]{1,2}$/;
                    if (!reg.test(_keyword)) {
                        return false;
                    } else {
                        return true;
                    }
                } else { /*非0开头的数字*/
                    var reg = /^[1-9]{1}[0-9]{0,10}[.]{0,1}[0-9]{0,2}$/;
                    if (!reg.test(_keyword)) {
                        return false;
                    } else {
                        return true;
                    }
                }
                return false;
            }
        }
    },
    destroyed: function() {
        if (this.inter) {
            clearInterval(this.inter);
        }
    },
    watch: {
        showWechatPayMask: function() {
            if (this.showWechatPayMask == false && this.inter) {
                clearInterval(this.inter);
            }
        },
        payType: function() {
            if (this.isPriceNumber(this.amount)) {
                var fee = 0;
                if (this.userinfo.local == 1 && this.payType == "wxpay") {
                    // 大陆微信
                    fee = this.wx_serviceFee;
                } else if (this.userinfo.local == 2 && this.payType == "wxpay") {
                    // 香港微信
                    fee = this.hk_wx_serviceFee;
                } else if (this.userinfo.local == 1 && this.payType == "alipay") {
                    // 大陆支付宝
                    fee = this.ali_serviceFee;
                } else if (this.userinfo.local == 2 && this.payType == "alipay") {
                    // 香港支付宝
                    fee = this.hk_ali_serviceFee;
                }
                this.service_amount = this.common.mul(this.amount, fee);
                this.service_amount = this.service_amount.toFixed(2);
                this.total_amount = this.common.add(this.service_amount, this.amount);
                this.total_amount = this.total_amount.toFixed(2);
            }
        },
        amount() {
            if (this.isPriceNumber(this.amount)) {
                var fee = 0;
                if (this.userinfo.local == 1 && this.payType == "wxpay") {
                    // 大陆微信
                    fee = this.wx_serviceFee;
                } else if (this.userinfo.local == 2 && this.payType == "wxpay") {
                    // 香港微信
                    fee = this.hk_wx_serviceFee;
                } else if (this.userinfo.local == 1 && this.payType == "alipay") {
                    // 大陆支付宝
                    fee = this.ali_serviceFee;
                } else if (this.userinfo.local == 2 && this.payType == "alipay") {
                    // 香港支付宝
                    fee = this.hk_ali_serviceFee;
                }
                this.service_amount = this.common.mul(this.amount, fee);
                this.service_amount = this.service_amount.toFixed(2);
                this.total_amount = this.common.add(this.service_amount, this.amount);
                this.total_amount = this.total_amount.toFixed(2);
            }
        }
    },
    components: {
        "v-user-menu": UserMenu
    }
}
</script>
<style scoped>
.page {
    margin: 20px 83px 44px 83px;
    display: flex;
}

.wechat-qrcode-box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
}

.wechat-qrcode-box div {
    background-color: white;
    flex-flow: column;
    height: 300px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.wechat-qrcode-box div .qecode-box {
    width: 200px;
    height: 200px;
    margin-top: 20px;
}

.wechat-qrcode-box div .close-icon {
    position: absolute;
    top: -40px;
    width: 30px;
    height: 30px;
    right: 3px;
}

.left-box {
    width: 196px;
    max-height: 1000px;
    min-height: 550px;
}

.body-box {
    width: 982px;
    margin-left: 22px;
}

.my-box {
    border: 1px solid rgba(182, 182, 182, 0.7);
    margin-bottom: 30px;
}

.title-box {
    display: flex;
    align-items: center;
    height: 50px;
    background: rgba(247, 246, 246, 1);
}

.title-box p {
    font-size: 16px;
    font-weight: 500;
    color: rgba(153, 153, 153, 1);
    line-height: 50px;
    margin-left: 30px;
}

.money-text {
    padding: 15px 30px;
}

.money-text>p {
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 18px;
}

.input-box {
    padding: 7px 15px;
    width: 120px;
    height: 18px;
    border-radius: 2px;
    border: 1px solid rgba(204, 204, 204, 0.7);
    margin-bottom: 35px;
}

.input-box input {
    width: 100%;
}

.mode {
    display: flex;
    align-items: center;
}

.mode>img {
    height: 20px;
    width: 20px;
    margin-right: 15px;
}

.mode div {
    width: 210px;
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px dashed rgba(151, 151, 151, 0.7);
}

.mode:last-child div {
    border-bottom: 0;
}

.mode div img {
    height: 36px;
    width: 36px;
    margin-right: 10px;
}

.mode div p {
    font-size: 14px;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: 20px;
}

.all-money {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
}

.money-num {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
}

.money-num div {
    width: 100px;
    text-align: right;
    font-size: 16px;
    font-weight: 500;
    color: rgba(153, 153, 153, 1);
    line-height: 20px;
    margin-right: 4px;
}

.money-num p {
    font-size: 20px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 20px;
}

.recharge {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
}

.recharge div {
    width: 60px;
    height: 26px;
    background: rgba(41, 127, 171, 1);
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 26px;
    text-align: center;
}

.over-text {
    height: 160px;
    font-size: 14px;
    color: #666;
    padding-left: 30px;
    padding-top: 35px;
}
</style>